import { Component, OnInit, Input } from '@angular/core';
import { ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { UsuarioService } from 'src/app/data-access/usuario.service';
import { ReservacionService } from 'src/app/data-access/reservacion.service';


import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Router } from '@angular/router';

interface Evento extends CalendarEvent {
  tipo: number;
  espacio: number;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-e-historico',
  templateUrl: './e-historico.component.html',
  styles: [
  ]
})
export class EHistoricoComponent implements OnInit {
  forma: FormGroup;
  model;
  p: number =1;
  Usuario;
  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private usuarioService: UsuarioService,
    private reservacionService: ReservacionService,
    private spinner: NgxSpinnerService,
    public modal: NgbModal,
    private router: Router
  ) {
    this.Usuario = this.usuarioService.getUser();
    this.iniciarForm();
    // let isAdmin = this.usuarioService.adm();
    // if (isAdmin) {
    //   this.router.navigate(['/menu-admin'])
    // }
  }

  ngOnInit(): void {
    this.addLista();
  }

  iniciarForm() {
    this.forma = this.fb.group({
      tipo: [0],
      espacio: [''],
      inicio: ['', [Validators.required]],
      hora: ['', [Validators.required]],
      duracion: [0, [Validators.required]],
      fin: ['', [Validators.required]],
      //fechaFin: [''],
      //activo: [true]
    })
    this.model = this.forma;
  }

  Reservacion;
  ReservacionLista;
  events: Evento[];
  addLista(){
    this.spinner.show();
    this.reservacionService.getData().toPromise()
    .then(data => {
      this.spinner.hide();
      this.Reservacion = data;
      this.ReservacionLista = this.Reservacion.filter(x => !this.dateIsValid(new Date(x.inicio)));
      this.ReservacionLista = this.ReservacionLista.filter(x => x.idUsuario == this.Usuario.idUsuario);
      
      let fecha;
      let d1;
      let d2;
      for(let i=0; i<this.ReservacionLista.length;i++){
        this.ReservacionLista[i]['horaInicio'] = this.ReservacionLista[i].inicio.split('T')[1].substring(0,5);
        this.ReservacionLista[i]['horaFin'] = this.ReservacionLista[i].fin.split('T')[1].substring(0,5);
      
        fecha = this.ReservacionLista[i].fin;
        //console.log(fecha);
        //fecha = fecha.split("T")[0] + ' ' + this.Reservacion[i]['horaFin'];
        //fecha = fecha.split("T")[0] + ' ' + '14:00';
        //console.log(fecha);
        d1 = new Date();
        d2 = new Date(fecha);
        //console.log(d1 + " > " + d2);
        this.ReservacionLista[i]["finalizada"] = false;
        if (d1.getTime() > d2.getTime()) {
          this.ReservacionLista[i]["finalizada"] = true;
          this.ReservacionLista[i].estatus = 'Realizada';
        }
      }

      console.log(this.ReservacionLista);
console.log("========================");

      this.events = this.ReservacionLista.map(x => ({
        //idReservacion: x.idReservacion,
        tipo: x.espacioTipo,
        espacio: x.espacio,
        start: x.inicio,
        end: x.fin,
        title: x.espacio,
        color: colors.blue
      }));

      //this.ReservacionLista = this.Reservacion;

    });
  }

  nuevo(content) {
    this.iniciarForm();
    this.model.activo = true;
    this.modal.open(content,{size:'xl'});
  }

  editar(content, modelo) {
    console.log(modelo);
    this.model = modelo;
    let dt = new Date(modelo.start);
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    this.model.inicio = dt; //new Date(modelo.start);
    this.model.hora = dt.toISOString().slice(11,16);
    //console.log(new Date(modelo.start).getTime());
    
    //this.model.hora = new Date(modelo.start).toISOString().substring(11,16);
    this.modal.open(content,{size:'xl'});
  }

  guardar() {
    /*
    this.spinner.show();
    console.log(this.forma.value);
    if (this.forma.controls["idSeccion"].value == undefined) {
      this.seccionService.addData(this.forma.value).subscribe(
        (res) => {this.recargar();this.spinner.hide();},
        (error) => {console.log(error);this.spinner.hide();});
    }
    else {
      this.seccionService.updData(this.forma.controls["idSeccion"].value, this.forma.value).subscribe(
          (res) => {this.recargar();this.spinner.hide();},
          (error) => {console.log(error);this.spinner.hide();});
    }
    */
  }
  
  recargar() {
    this.modal.dismissAll();
    this.addLista();
  }

  formatDate(fecha) {
    var d = new Date(fecha);
    //d.setHours(0, 0, 0, 0);
    return [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),
      
    ].join('-');
  }
  
  uf_Date(fecha) {
    var d = new Date(fecha);
    //d.setHours(0, 0, 0, 0);
    return [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear()
    ].join('/');
  }

  util_fdt(fecha) {
    var d = new Date(fecha);
    return d.toLocaleString();
    /*
    let hora = fecha.split('T')[1].split('.')[0];
    return util.formatDate(fecha) + ' ' + hora;
    */
  }

  minDate: Date = new Date();
  dateIsValid(date: Date): boolean {
    return date >= this.minDate;
  }
  
  /*

  events: Evento[] = [
    {
      tipo: 1,
      espacio: 1,
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'Cubículo 01',
      color: colors.red,
      actions: null,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
    {
      tipo: 1,
      espacio: 2,      
      start: startOfDay(new Date()),
      end: addDays(endOfMonth(new Date()), 1),
      title: 'Cubículo 02',
      color: colors.yellow,
      actions: null,
    },
    {
      tipo: 2,
      espacio: 3,      
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'Sala de Audio',
      color: colors.blue,
      allDay: true,
    },
    {
      tipo: 2,
      espacio: 4,      
      start: addHours(startOfDay(new Date()), 2),
      end: addHours(new Date(), 2),
      title: 'Sala de Video',
      color: colors.yellow,
      actions: null,
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
    },
  ];
  */

}





